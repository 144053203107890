@import './app/globals/colors.scss';

::ng-deep .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected {
  color:white !important;
  background: var(--primary) !important;
}


::ng-deep .dx-texteditor.dx-state-focused {
  border-color: var(--primary) !important;
}

::ng-deep .dx-texteditor.dx-state-hover {
  border-color: var(--primary--opacity--0_3) !important;
}

::ng-deep .dx-texteditor.dx-state-hover.dx-state-focused {
  border-color: var(--primary) !important;
  opacity: unset;
}

::ng-deep .dx-button-mode-contained.dx-button-default {
  background-color: $button !important;
}

::ng-deep .dx-button-mode-contained.dx-button-default-dx-state-hover {
  background-color: var(--primary--opacity--0_3) !important;
}

::ng-deep .dx-item.dx-list-item.dx-list-item-selected.dx-state-active {
  background-color: var(--primary) !important;
}

::ng-deep .dx-item.dx-list-item.dx-state-active {
  background-color: var(--primary) !important;
}

::ng-deep .dx-button-mode-text.dx-button-success{
  color: var(--primary) !important;
}

::ng-deep .dx-tab.dx-tab-selected {
  background-color: $button;
}

::ng-deep .dx-icon-add {
  color: $button !important;
}

::ng-deep .dx-button-mode-text.dx-button-success .dx-icon{
  color: var(--primary) !important;
}


:host ::ng-deep .dx-tabpanel-container {
  background-color: white !important;
}

:host ::ng-deep .dx-multiview-item {
  padding-left: 20px;
  padding-right: 20px;
}

::ng-deep .dx-tab-text {
  vertical-align: middle;
  line-height: 40px;
  font-size: 17px;
  color:  var(--secondary) !important;
  font-weight: 600;
}

::ng-deep .dx-tab-selected .dx-tab-text {
  color:  #fff !important;
}

::ng-deep .dx-tabs,
.dx-tab {
  background-color: #F4F4F4 !important;
}

::ng-deep .dx-tab {
  border-right: 5px solid #F4F4F4 !important;
  border-bottom: 5px solid #F4F4F4 !important;
}

::ng-deep .options {
  padding: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 260px;
  top: 0;
  background-color: rgba(191, 191, 191, 0.15);
}

::ng-deep .caption {
  font-size: 18px;
  font-weight: 500;
}

::ng-deep.option {
  margin-top: 10px;
}

::ng-deep .add-mitglied-button {
  margin-top: 10px;
}

::ng-deep .dx-form-validation-summary {
  text-align: right;
}

::ng-deep .dx-icon-remove {
  color: red !important;
}

::ng-deep .dx-icon-add {
  color: var(--secondary) !important;
}

:host ::ng-deep .ueberschrift2 span.dx-form-group-caption {
  font-size: 16px !important;
}

:host ::ng-deep .preistabelle {
  background-color: #f4f4f4;
  font-size: 16px;
}

:host ::ng-deep .preistabelleright {
  background-color: #f4f4f4;
  font-size: 16px;
  text-align: right;
}

:host ::ng-deep .gesamtbetrag {
  text-align: right;
  color: white;
  font-weight: bold;
  font-size: 22px !important;
}

:host ::ng-deep .gesamtsumme {
  text-align: right;
  color: white;
  font-weight: bold;
  font-size: 16px !important;
}

:host ::ng-deep .betrag {
  text-align: right;
  color: black;
  font-size: 16px !important;
}

:host ::ng-deep .gesamtRow {
  background-color: #969292;
  text-align: right;
  padding: 3px;
  margin: 3px;
}

:host ::ng-deep .ueberschrift2 {
  font-size: 18px;
}

:host ::ng-deep  div.dx-tab-selected>div.dx-item-content>span.dx-tab-text {
  color: white !important;
}

::ng-deep .dx-texteditor-buttons-container > .dx-button.dx-button-has-icon:not(.dx-button-has-text) > .dx-button-content{
padding: 1px;
}


:host ::ng-deep .edit {
  text-align:right;
}

:host ::ng-deep .buttonzurueck,
a.buttonzurueck {
  font-size: 26px !important;
  font-family: Source Sans Pro !important;
  text-transform: none !important;
  font-size: 19px !important;
  line-height: 28px !important;
  font-weight: 600 !important;
  border: none !important;
  ;
}

:host ::ng-deep .activemenu {
color:white;
background: var(--primary);
min-height:50px;
margin:1px;
font-size:16px;
padding:15px;
}
// :host ::ng-deep .activemenu {
//     color:white;
//     background:#7AB500;
//     // min-height:50px;
//     // margin:1px;
//     // font-size:16px;
//     // padding:15px;
// }

:host ::ng-deep .nonactivemenu {
color:#046F2D;
background:#F5F5F5;
min-height:50px;
margin:1px;
font-size:16px;
padding:15px;
}

// :host ::ng-deep .nonactivemenu {
//     color:#046F2D;
//     background:#F5F5F5;
//     // min-height:50px;
//     // margin:1px;
//     // font-size:16px;
//     // padding:15px;
// }

:host ::ng-deep h3 {
  color: var(--secondary);
}

:host ::ng-deep .profil-container > dx-responsive-box > div > div > div > div:nth-child(1) {
  // max-width:230px;
  min-width:230px !important;
  @media (min-width: 715px) {
    max-width:230px;
  }
}

:host ::ng-deep  .profil-container > dx-responsive-box > div > div > div > div:nth-child(2) > div {
margin-left: 10px;
}

:host ::ng-deep .formuebersicht {
  background:#F4F4F4 !important;
  padding:0px !important;
}

:host ::ng-deep .formuebersicht2 {
  padding:0px !important;
}

:host ::ng-deep .formedit {
  background:white !important;
  padding:0px !important;
}

:host ::ng-deep dx-button {
  margin: 5px;
}

:host ::ng-deep .itemright {
  text-align: right;
}

::ng-deep .add-mitglied-button {
  margin-top: 10px;
}

:host ::ng-deep .formbutton{
  background-color: transparent!important;
  padding-left: 0px !important;
}

::ng-deep #accordion h1 {
  font-size: 20px;
}

::ng-deep #accordion h1,
::ng-deep #accordion p {
  margin: 0;
}

::ng-deep .dx-theme-material #accordion .dx-accordion-item-title {
  display: flex;
}

::ng-deep .dx-theme-material #accordion h1 {
  align-self: center;
}

::ng-deep .options,
::ng-deep .selected-data {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

::ng-deep .selected-data {
  position: relative;
  height: 36px;
}

::ng-deep .selected-data > .caption {
  position: relative;
  top: 5px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 115%;
}

::ng-deep .selected-data > .dx-widget {
  position: absolute;
  left: 140px;
  right: 20px;
  top: 20px;
}

::ng-deep .options > .caption {
  font-weight: 500;
  font-size: 18px;
}

::ng-deep .option {
  margin-top: 10px;
}

::ng-deep .topay {
  background-color: var(--primary); 
  color: #ffffff;
}

::ng-deep .topay.dx-button-mode-contained.dx-state-hover {
  background-color: var(--secondary);
  border-color: #f1f1f1;
}

::ng-deep .paying {
  background-color: var(--secondary); 
  color: #ffffff;
}

::ng-deep .paying.dx-button-mode-contained.dx-state-hover {
  background-color: var(--secondary);
  border-color: #f1f1f1;
}



@import './assets/devextreme/css/dx.common.css';
/* @import './assets/devextreme/css/dx.light.css'; */
@import './assets/devextreme/css/dx.generic.custom-scheme.css';
/* Form */
dx-form {
  background-color: white !important;
  padding: 40px !important;
}
.main, .formbackground {
  padding: 20px !important;
  background-color:#f4f4f4;
}
/* .inner-wrap {
  background-color:#f4f4f4;
} */

@media only screen and (max-width: 690px) {
  dx-form {
    padding: 20px !important;
  }
  .main {
    padding: 20px !important;
  }
  .dx-multiview-item-content {
    padding: 0px !important;
  }
  .formbackground{
    padding: 20px !important;
  }
}

.dx-field-item-label-location-top{
  padding-bottom: 5px;
}
.dx-form-group-with-caption > .dx-form-group-content {
  padding-bottom: 0px !important;
}
.dx-form-group {
  padding-top:20px !important;
}

/* Validierung */
.dx-form-validation-summary {
  padding-top:10px !important;
}
.dx-validationsummary-item-content {
  border-bottom: 0px;
  display: inline-block;
  line-height: normal;
  font-size:12px;
  border-bottom: none !important;
}
.dx-validationsummary-item {
  display:inline-block;
  background-color: #D9534F;
  color:white !important;
  padding: 5px;
  border: 1px solid #D9534F;
  margin: 5px;
  text-decoration: none !important;
}

/* Icons */
.dx-icon-remove {
  color:red !important;
}